
  import { Component, Vue } from "vue-property-decorator";
  import LzTable from "@/components/Table.vue";
  import LzButton from "@/components/Button.vue";
  import Articles from "../api";
  import { namespace } from "vuex-class";
  import LzConfirm from "@/components/Confirm.vue";
  import SectionToggle from "@/components/SectionToggle.vue";
  const auth = namespace("auth");

  type Article = {
    id: string;
    title: string;
    description: string;
    status: "enabled" | "disabled";
    date: string;
    icon: string;
  };

  @Component({ components: { LzTable, LzButton, LzConfirm, SectionToggle } })
  export default class Read extends Vue {
    @auth.State("id")
    memberId!: string;

    loaded = false;

    articleFields = [
      { id: "title", label: this.$t("blog.read.table.headers.title") },
      {
        id: "status",
        label: this.$t("blog.read.table.headers.status")
      },
      {
        id: "date",
        label: this.$t("blog.read.table.headers.date")
      },
      { id: "actions", label: this.$t("blog.read.table.headers.actions") }
    ];

    // TODO: Replace with real data when the API is ready
    articles: Article[] = [];

    mounted() {
      this.getArticles().finally(() => {
        this.loaded = true;
      });
    }

    async getArticles() {
      await Articles.getArticlesByMemberId(this.memberId).then(articles => {
        this.articles = articles;
      });
    }

    deleteModal = false;
    articleToDelete: Article;
    openDeleteModal(item: Article) {
      this.deleteModal = true;
      this.articleToDelete = item;
    }
    async deleteArticle() {
      if (!this.articleToDelete) return;

      await Articles.delete(this.articleToDelete.id)
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$tc("blog.create.notifications.deleted")
          });
          this.$router.push({ name: "blogRead" });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic")
          });
        });

      await this.getArticles().finally(() => {
        this.deleteModal = false;
      });
    }
  }
